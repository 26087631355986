.features-section {
  padding: 60px 0;
}

.features-title {
  margin-bottom: 28px;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(
    to right,
    var(--main-accent-color),
    var(--secondary-accent-color)
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.features-desc {
  max-width: 550px;
  margin: 0 auto 16px auto;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.features-item {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 22px 12px;
}

.features-svg {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.features-item-title {
  margin: 0 auto 8px auto;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

.features-item-desc {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

@media screen and (min-width: 768px) {
  .features-item {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1280px) {
  .features-list {
    display: flex;
    flex-direction: row;
  }

  .features-item {
    width: calc((100% - 40px) / 3);
  }
}
