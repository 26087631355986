.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 16px 0;
  background-color: var(--main-accent-color);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.header-logo {
  font-size: 36px;
  font-weight: 600;
  color: var(--light-text-color);
}

.header-nav {
  display: none;
}

.header-menu-btn {
  display: flex;
  padding: 4px;

  background-color: transparent;
  border: none;
  outline: none;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 70vw;
  height: 100vh;
  padding: 16px 15px;

  background-color: var(--main-bg-color);
  transform: translateX(0);
  transition: transform 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

.backdrop.is-hidden .mobile-menu {
  transform: translateX(100%);
}

.mobile-close-btn {
  display: flex;
  margin: 0 0 30px auto;
  padding: 4px;

  background-color: transparent;
  border: none;
  outline: none;
}

.mobile-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mobile-item {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 400;
  text-align: center;
  color: var(--main-text-color);
  transition: color 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

@media screen and (min-width: 375px) {
  .header-container {
    padding: 0 20px;
  }

  .mobile-menu {
    padding: 16px 20px;
  }
}

@media screen and (min-width: 768px) {
  .header-container {
    padding: 0 32px;
  }

  .header-nav {
    display: flex;
  }

  .header-list {
    display: flex;
    gap: 40px;
  }

  .header-item {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.02em;
    font-weight: 400;
    text-align: center;
    color: var(--light-text-color);
    transition: color 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
    cursor: pointer;
  }

  .header-item:hover {
    color: var(--secondary-accent-color);
  }

  .header-active-link {
    color: var(--secondary-accent-color);
  }

  .header-menu-btn {
    display: none;
  }

  @media screen and (min-width: 1280px) {
    .header-container {
      padding: 0 100px;
    }
  }
}
