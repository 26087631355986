.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  padding: 30px 0 30px 0;
  background-color: var(--add-third-color);
}

.footer-container {
  padding: 0 15px;
}

.footer-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.footer-logo {
  font-size: 16px;
  font-weight: 600;
  color: var(--light-text-color);
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 20px;
}

.footer-item {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: var(--light-text-color);
  cursor: pointer;
  transition: color 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

.footer-item:hover {
  color: var(--secondary-accent-color);
}

.footer-btn {
  flex-shrink: 0;
}

.footer-registration {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: var(--light-text-color);
}

.footer-copy {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: var(--light-text-color);
}

.footer-links {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.fotter-links-svg {
  display: block;
  cursor: pointer;
  transition: scale 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

.fotter-links-svg:hover,
.fotter-links-svg:hover {
  scale: 1.2;
}

@media screen and (min-width: 375px) {
  .footer-container {
    padding: 0 20px;
  }

  .footer-logo {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .footer-container {
    padding: 0 32px;
  }

  .footer-links {
    margin-bottom: 0;
  }

  .footer-links-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: end;
  }
}

@media screen and (min-width: 1280px) {
  .footer-container {
    padding: 0 100px;
  }
}
