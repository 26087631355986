@font-face {
  font-family: 'montserrat';
  src: url(../fonts/Montserrat-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'montserrat';
  src: url(../fonts/Montserrat-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'montserrat';
  src: url(../fonts/Montserrat-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'montserrat';
  src: url(../fonts/Montserrat-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

:root {
  --main-text-color: #60697b;
  --light-text-color: #f9fafb;
  --placeholder-text-color: rgba(96, 105, 123, 0.6);
  --main-bg-color: #ffffff;
  --main-accent-color: #3a6ee1;
  --secondary-accent-color: #04cb64;
  --add-first-color: #ecf404;
  --add-second-color: rgba(58, 111, 225, 0.18);
  --add-third-color: #3293b2;
  --btn-bg-color: #f9fafb;
  --backdrop: rgba(10, 19, 39, 0.426);
}

body {
  font-family: montserrat, sans-serif;
  font-weight: 400;
  color: var(--main-text-color);
  background: var(--main-bg-color);
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 334px;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
}

.common-text {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

.common-text-blue {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: var(--add-third-color);
}

.common-text-bold {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.mb20 {
  margin-bottom: 20px;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.is-hidden {
  opacity: 0;
  visibility: 0;
  pointer-events: none;
}

.no-scroll {
  overflow: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;

  background-color: var(--backdrop);
  transition: opacity 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77),
    visibility 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

.line {
  width: 140px;
  height: 2px;
  margin: 0 auto;
  background-color: var(--main-accent-color);
}

.base-btn {
  display: inline-block;
  padding: 14px 28px;

  font-family: inherit;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: var(--light-text-color);

  background-color: transparent;
  border: 1px var(--light-text-color) solid;
  border-radius: 32px;

  transition: color 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77),
    background-color 250ms cubic-bezier(0.23, 0.86, 0.79, 0.77);
}

.base-btn:hover,
.base-btn:focus {
  color: var(--main-text-color);
  background-color: var(--btn-bg-color);
  outline: none;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 4px auto;
  position: relative;
  background: var(--add-second-color);
  box-shadow: -18px 0 var(--add-second-color), 18px 0 var(--add-second-color);
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: var(--add-second-color);
    box-shadow: -18px 0 var(--main-accent-color), 18px 0 var(--add-second-color);
  }
  66% {
    background: var(--main-accent-color);
    box-shadow: -18px 0 var(--add-second-color), 18px 0 var(--add-second-color);
  }
  100% {
    background: var(--add-second-color);
    box-shadow: -18px 0 var(--add-second-color), 18px 0 var(--main-accent-color);
  }
}

@media screen and (min-width: 375px) {
  .container {
    max-width: 375px;
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0 32px;
  }

  .content-wrap {
    padding-bottom: 278px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding: 0 100px;
  }
}
