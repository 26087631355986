.about-container {
  padding: 116px 0 40px 0;
}

.about-title {
  margin-bottom: 18px;
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 20px;

  background: linear-gradient(
    to right,
    var(--main-accent-color),
    var(--secondary-accent-color)
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.about-description {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

.about-team-title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.about-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about-card-photo {
  width: 180px;
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.about-card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about-card-name {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.about-card-role {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.about-card-description {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.02em;
}

@media screen and (min-width: 768px) {
  .about-card {
    flex-direction: row;
    gap: 40px;
  }
}
