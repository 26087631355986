.hero-section {
  padding: 116px 0 80px 0;
  background-color: var(--main-accent-color);
  color: var(--light-text-color);
}

.hero-title {
  margin: 0 auto 40px auto;
  font-size: 42px;
  line-height: 1.4;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;

  background: linear-gradient(
    to right,
    var(--add-first-color),
    var(--secondary-accent-color),
    var(--light-text-color)
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-descrip {
  max-width: 800px;
  margin: 0 auto 60px auto;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-weight: 400;
  text-align: center;
}

.hero-btn {
  text-align: center;
}

@media screen and (min-width: 375px) {
  .hero-title {
    font-size: 48px;
    letter-spacing: 0.02em;
  }
}
